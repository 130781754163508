/* Custom styling for individual components on partner page  */

.tractor-supply .partner-caregiver-button {
  @apply bg-[#DA3832];
}
.tractor-supply .partner-caregiver-button:disabled {
  @apply bg-[#DA3832];
  @apply opacity-20;
}

.tractor-supply .partner-caregiver-button:focus-visible {
  @apply bg-[#DA3832];
  @apply opacity-90;
}

.tractor-supply .partner-caregiver-button:hover {
  @apply bg-[#c7322c];
}

.tractor-supply .discount-background {
  @apply bg-[#7D9C29];
}


.petsense .partner-caregiver-button {
  @apply  bg-[#003E60];
}

.petsense .partner-caregiver-button:disabled {
  @apply bg-[#003E60];
  @apply opacity-20;
}

.petsense .partner-caregiver-button:focus-visible {
  @apply bg-[#003E60];
  @apply opacity-90;
}

.petsense .partner-caregiver-button:hover {
  @apply bg-[#00334d];
}

.petsense .discount-background {
  @apply bg-[#DEF2F9];
  @apply text-[#222222];
}

.mesa .partner-caregiver-button {
  @apply  bg-[#3C5180];
}

.mesa .partner-caregiver-button:disabled {
  @apply bg-[#3C5180];
  @apply opacity-20;
}

.mesa .partner-caregiver-button:focus-visible {
  @apply bg-[#3C5180];
  @apply opacity-90;
}

.mesa .partner-caregiver-button:hover {
  @apply bg-[#00334d];
}

.mesa .discount-background {
  @apply bg-[#00dfc9];
  @apply text-[#222222];
}

.amenify .partner-caregiver-button {
  @apply  bg-[#3C5180];
}

.amenify .partner-caregiver-button:disabled {
  @apply bg-[#3C5180];
  @apply opacity-20;
}

.amenify .partner-caregiver-button:focus-visible {
  @apply bg-[#3C5180];
  @apply opacity-90;
}

.amenify .partner-caregiver-button:hover {
  @apply bg-[#3C5180];
}

.amenify .discount-background {
  @apply bg-[#0078FF];
  @apply text-[#FFFFFF];
}

.services-carousel-title {
  @apply max-sm:my-[20px];
  @apply md:mt-[100px];
  @apply lg:mt-[20px];
}

.tractor-supply .services-carousel-title{
  @apply max-sm:mt-[90px];
}

.bright-horizons .partner-title {
  @apply max-sm:mt-[300px];
  @apply md:mt-[100px];
}

@media (max-width:358px)  {
  .bright-horizons .partner-title {
    margin-top: 150px;
  }
}


.bright-horizons .partner-caregiver-button {
  @apply  bg-[#1A475F];
}

.bright-horizons .partner-caregiver-button:disabled {
  @apply bg-[#1A475F];
  @apply opacity-20;
}

.bright-horizons .partner-caregiver-button:focus-visible {
  @apply bg-[#1A475F];
  @apply opacity-90;
}

.bright-horizons .partner-caregiver-button:hover {
  @apply bg-[#1A475F];
}

.bright-horizons .discount-background {
  @apply bg-[#1A475F];
}

.bright-horizons .section-title {
  @apply text-[20px] md:text-[30px] font-[700] text-[#1A475F];
}

.bright-horizons .faq-text {
  @apply text-[#1A475F]; 
}

.bright-horizons .partner-hero-title {
  @apply max-sm:mb-[100px];
}

/* @media(min-width: ) */

@media (min-width: 380px) and (max-width:602px) {
  .petsense .partner-hero-title {
    margin-bottom: 400px;
  }
}

@media (min-width: 380px) and (max-width:602px) {
  .mesa .partner-hero-title {
    margin-bottom: 400px;
  }
}

@media (min-width: 380px) and (max-width:602px) {
  .amenify .partner-hero-title {
    margin-bottom: 400px;
  }
}

.bright-horizons .partner-hero {
  @apply md:h-[500px];
}

.tractor-supply #embla__arrow {
  fill: #DA3832;
}

.bright-horizons #embla__arrow {
  fill: #F9CD46;
}

.tractor-supply #carousel__icon__fill {
  fill: #DA3832;
}

.tractor-supply #carousel__icon__stroke {
  stroke: #DA3832;
}

.bright-horizons #carousel__icon__fill {
  fill: blueviolet;
}

.bright-horizons #carousel__icon__stroke {
  stroke: blueviolet;
}

.petsense #carousel__icon__stroke {
  stroke: #FFBC00;
}

.petsense #carousel__icon__fill {
  fill: #FFBC00;
}

.mesa #carousel__icon__stroke {
  stroke: #00BF8F;
}

.mesa #carousel__icon__fill {
  fill: #00BF8F;
}

.amenify #carousel__icon__stroke {
  stroke: #00BF8F;
}

.amenify #carousel__icon__fill {
  fill: #00BF8F;
}