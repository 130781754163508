@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'partners.css';

@layer base {
  h1 {
    @apply text-[26px] md:text-[34px] font-[700] text-black leading-[1.4];
  }

  h2 {
    @apply text-[20px] md:text-[30px] font-[700] text-darkGray;
  }

  h3 {
    @apply text-[18px] md:text-[22px] font-[700] text-darkGray leading-[1.3];
  }

  h4 {
    @apply text-[16px] md:text-[18px] font-[600] text-darkGray;
  }

  h5 {
    @apply text-[14px] md:text-[16px] font-[600] text-darkGray;
  }

  p {
    @apply text-[14px] md:text-[16px] text-wagGray;
  }
}

@layer utilities {
  .h-screen {
    height: 100vh;
    height: 100dvh;
  }

}


.walker-profile-gallery .swiper-pagination-fraction {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px;
  width: fit-content !important;
  left: unset !important;
  right: 6px;
  font-size: 14px;
  border-radius: 6px;
}

@media (min-width: 650px) and (max-width: 766px) {
  .text-container {
    max-width: 450px;
  }
  .copy-to-clipboard {
    width: 50%;
  }
  .continue-button {
    width: 50%
  }
  .background-image {
    bottom: 6px;
    right: 0px
  }
  .modal-subheader-text {
    max-width: 50%;
  }
  .modal-terms-text {
    max-width: 50%;
  }
}

@media (min-width: 650px) {
  .walker-profile-gallery .swiper-pagination-fraction {
    display: none;
  }
  .walker-profile-gallery .swiper-button-next button, .walker-profile-gallery .swiper-button-prev button {
    background: white;
  }

  .walker-profile-gallery .swiper-button-next {
    right: 15.5% !important;
  }

  .walker-profile-gallery .swiper-button-prev {
    left: 15.5% !important;
  }

  .walker-profile-gallery .swiper-button-next button svg, .walker-profile-gallery .swiper-button-prev button svg {
    fill: #32AA80;
  }

  .walker-profile-gallery .swiper-button-next button svg path,
  .walker-profile-gallery .swiper-button-prev button svg path {
    fill: #32AA80;
    stroke-width: 0px;
  }
}

.tailwind p {
  @apply leading-[1.6];
}

.section-title {
  @apply text-[20px] md:text-[30px] font-[700] text-[#222222];
}


.tailwind h2 {
  @apply leading-[1.3];
}

.service-details-service-select :has + path {
  stroke: red;
}

#walker-availability-calendar table button {
  border-radius: 6px;
  pointer-events: none;
  background-color: #32AA80;
  color: white;
  width: 30px;
  height: 30px;
  font-weight: 500;
}

#walker-availability-calendar table button:disabled {
  background-color: white;
  color: #272727;
}

#walker-availability-calendar table {
  border-top: none;
}

#booking-journey-calendar button, #caregiver-calendar button {
  font-family: 'new-hero';
}

#booking-journey-calendar table, #caregiver-calendar table {
  border-top: none;
  margin-top: 0;
}

#booking-journey-calendar table button, #caregiver-calendar table button {
  border-radius: 8px;
  color: #222222;
  width: 30px;
  height: 30px;
  font-weight: 400;
}
#caregiver-calendar table button {
  background-color: rgba(0, 191, 143, .15);
  border: 1px solid #32AA80;
}

#booking-journey-calendar table button {
  background-color: #fff;
  border: none;
}

#booking-journey-calendar table button:disabled, #caregiver-calendar table button:disabled {
  background-color: white;
  color: #272727;
  border: none;
}

#booking-journey-calendar table td, #caregiver-calendar table td {
  margin-left: 6px;
  margin-right: 6px;
}

#booking-journey-calendar thead tr th, #caregiver-calendar thead tr th {
  color: #999999;
  font-size: 10px;
  font-weight: 600;
  text-transform: capitalize;
}


.text-wrap-balance {
  text-wrap: balance;
}

.backface-invisible {
  backface-visibility: hidden;
}

@media (min-width: 750px) {
  .embla__dots, .embla__buttons {
    display: none;
  }
}

.tailwind #map-container .mapboxgl-popup-tip {
  display: none;
}

.tailwind #map-container .mapboxgl-popup-content {
  @apply shadow-lg rounded-xl font-new-hero p-0;
}

.tailwind #map-container .mapboxgl-popup {
  @apply !max-w-full
}

.tailwind .mapboxgl-ctrl.mapboxgl-ctrl-group {
  margin: 70px 10px 0 0;
}

.container-height {
  height: calc(100vh - 60px);
  height: calc(100dvh - 60px);
}

.modal-container-height {
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
}


@media (min-width: 750px) {
  .modal-container-height {
    height: calc(100vh - 90px);
    height: calc(100dvh - 90px);
  }
}

.modal-sheet-height {
  height: calc(100vh - 120px);
  height: calc(100dvh - 120px);
}

.profile-list-height {
  height: calc(100vh - 220px);
  height: calc(100dvh - 220px);
}

.caregiver-modal-height {
  height: 100vh;
  height: calc(100dvh);
}

@media (min-width: 750px) {
  .caregiver-modal-height {
    height: 90vh;
    height: calc(90dvh);
  }
}

.h-screen {
  height: 100vh;
  height: calc(100dvh);
}

.ticket-box-top {
  overflow:hidden;
  position: relative;
  clip-path: polygon(
    0 15%,
    3% 0,
    97% 0,
    100% 15%,
    100% 100%,
    100% 100%,
    0% 100%,
    0% 100%,
    0% 0%
  );
}

.ticket-box-bottom {
  clip-path: polygon(
    0 0%,
    0% 0,
    100% 0,
    100% 15%,
    100% 93%,
    97% 100%,
    3% 100%,
    0% 93%,
    0% 0%
  );
}

.caregiver-modal-calendar #caregiver-calendar tbody button {
  pointer-events: none;
}

@media (max-width: 370px) {
  .margin-top-mobile {
    margin-top: 20px;
  }
}

.custom-arrow-shadow {
  position: relative;
  top: -1px;
  stroke-width: 1px;
  z-index: -1;
}

.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 0.5rem;

  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: var(--4, 4px);
  border: 2px solid var(--Stroke-gray200, #D5D5D5);
  background: var(--Fill-white, #FFF);
}

.custom-checkbox:checked {
  background-color: white;  /* White background */
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%233b82f6' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13 4.5L6 11.5L3 8.5L4 7.5L6 9.5L12 3.5L13 4.5z'/%3e%3c/svg%3e");
  background-size: 16px;
  border: 2px solid #2866D3;
}


.green-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #E5E5E5;
  background-color: white;
  border-radius: 0.25rem;
  outline: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin-right: 0.5rem;
}

.green-checkbox:checked {
  background-color: #10B981;  /* Green background (you can adjust this to your specific green) */
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  border-color: #10B981;  /* Match the border color to the background */
}

.custom-box-shadow {
  box-shadow: 0px 8px 24px 0px #959DA533;
}